.about {
  background-color: #191919;
}
.about__img {
  border-radius: 10px;
  width: 100%;
}
h1.about__heading {
  margin-bottom: 10px;
  font-weight: 700;
  text-align: start;
  font-family: Monserrat, sans-serif;
  font-size: 60px;
}
.githubIcon {
  height: 32px;
  width: 45px;
  margin-right: 10px;
}
button.about.btn.pointer {
  text-transform: capitalize;
  width: 195px;
  height: 55px;
  font-size: 18px;
  transition: 0.4s ease;
}
.about__button {
  padding: 20px 0px;
}
a.bottom__to__top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 3%;
  right: 2%;
  background-color: #f9004d;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 0.4s all ease;
}
a.bottom__to__top.active {
  display: flex;
}
/* RESPONSIVE */
@media only screen and (max-width: 1024px) {
  .p__color {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 425px) {
  button.about.btn.pointer {
    text-transform: capitalize;
    width: 165px;
    margin-bottom: 8px;
    height: 50px;
    font-size: 13px;
    transition: 0.4s ease;
  }
}
