h1.heading {
  font-size: 60px;
  text-align: center;
  font-family: poppins sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
p.heading.p__color {
  margin-bottom: 0;
  text-align: center;
  line-height: 1.2;
}
.service__box {
  border-radius: 10px;
  width: 100%;
  background-color: #191919;
  padding: 55px;
  transition: 0.4s ease;
}
.service__box:hover {
  transform: translateY(5px);
  background-image: linear-gradient(90deg, #f61b10, #ef0963);
}
.service__box:hover .icon {
  color: #fff;
}
.service__box:hover p.service__text.p__color {
  color: #fff !important;
}
.icon {
  font-size: 54px;
  font-weight: 400;
  margin-bottom: 23px;
  display: inline-flex;
  color: #f9004d;
}
h1.service__text {
  font-size: 19px;
  font-family: poppins sans-serif;
  padding: 10px 0px;
}

p.service__text.p__color {
  margin-bottom: 0;
}
@media only screen and (max-width: 1024px) {
  .service__box {
    padding: 32px !important;
  }
}
