.home__bg {
  background-image: url("./img/back.jpeg");
  background-color: #010101;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
li.nav__items {
  color: white;
  font-size: 18px;
  /* padding-bottom: 15px; */
}
.home__content {
  padding: 125px 0px;
}
h1.home__text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3px;
  color: rgba(198, 201, 216, 0.75) !important;
}
h2.home__text {
  font-size: 54px;
  line-height: 54px;
  font-weight: 800;
  font-family: Montserrat, sans-serif;
}
h3.home__text {
  font-size: 50px;
  font-weight: 800;
  font-family: Montserrat, sans-serif;
  line-height: 40px;
}
h4.home__text {
  font-size: 50px;
  font-weight: 800;
  line-height: 40px;
  font-family: Montserrat, sans-serif;
}
.header {
  transition: 0.4s all ease;
}
h1 {
  font-size: 30px;
}
.header.active {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 0px 30px;
  background-color: #191919;
}
svg.bi.bi-justify.white.pointer {
  width: 40px;
  height: 40px;
}
.toggle__menu {
  display: flex;
  justify-content: flex-end;
  float: right;
  z-index: 1000;
  width: 100%;
  transition: all 1s ease;
  z-index: 2;
  flex: 1;
}
.sidebar {
  display: none;
}
.toggle__menu {
  display: none;
}
ul.sidebar.d__flex {
  flex-wrap: wrap;
  height: 100%;
  position: fixed;
  right: 0%;
  justify-content: center;
  background: #010101;
  top: 0%;
  flex-direction: column;
}
li.sideNavbar {
  font-size: 18px;
  padding: 25px 30px;
}
@media only screen and (max-width: 768px) {
  .sidebar {
    display: block;
  }
  .toggle__menu {
    display: block;
  }
  .header {
    justify-content: space-between;
  }
  .navigation.pxy__30 {
    display: none;
  }
  h2.white.pz__10 {
    font-size: 50px !important;
  }
  svg.bi.bi-justify.white.pointer {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 425px) {
  h2.home__text.pz__10 {
    font-size: 32px;
  }
  h3.sweet.pz__10 {
    font-size: 40px;
  }
  h1.home__text {
    font-size: 14px;
  }
  h4.home__text {
    font-size: 45px;
  }
}
